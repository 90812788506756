.introSlide {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #5C4033;

  .h1Letter1 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 23vw;
    top: 30vh;
  }

  .h1Letter2 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 30vw;
    top: 30vh;
  }

  .h1Letter3 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 37vw;
    top: 30vh;
  }

  .h1Letter4 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 44vw;
    top: 30vh;
  }

  .h1Letter5 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 54vw;
    top: 30vh;
  }

  .h1Letter6 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 62vw;
    top: 30vh;
  }

  .h1Letter7 {
    color: white;
    font-size: 10vw;
    position: fixed;
    left: 70vw;
    top: 30vh;
  }

  .h4Letter1 {
    color: #C3A699;
    font-size: 3vw;
    position: fixed;
    margin-top: 12vh;
    z-index: 0;
  }
}

.introSlideBuffer {
  width: 100vw;
  height: 200vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #5C4033;
}


.tableOfContentsSlide {
  scroll-snap-align: start;
  height: 100vh;
  background-color: #ECDCD1;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    z-index: 1;
    width: 20vw;
  }

  .menuContainer {
    position: absolute;
    height: 20vw;
    width: 20vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 20vw;

    .active {
      color: #785444 !important;
    }
  }

  .menuContainer p {
    z-index: 1;
    color: #C3A699;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 2vw;
    margin: 3px;
    transition: color 0.8s;
  }

  .menuContainer p:hover {
    transition: color 0.8s;
    color: #785444;
    cursor: pointer;
  }



  .menuContainer.fixed {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transition: top 1s;
  }


}

.whatIsContainer {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  background-color: #ECDCD1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .whatIsText {
    z-index: 1;
    height: 30vh;
    width: 60vw;
    margin-right: 20vw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #785444;
    font-size: 2.5vw;
    font-weight: 500
  }
}

.ourVisionContainer {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  background-color: #ECDCD1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .ourVisionText {
    z-index: 1;
    height: 40vh;
    width: 60vw;
    margin-right: 20vw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #785444;
    font-size: 2.5vw;
    font-weight: 500;

    .highlighted {
      color: #C3A699;
    }
  }
}

.keyFeaturesContainer {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  background-color: #ECDCD1;
  display: flex;
  justify-content: center;
  align-items: center;

  .carouselContainer {
    z-index: 1;
    width: 60vw;
    height: 85vh;
    margin-right: 20vw;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: hidden;
    flex-direction: row;

    .buttonLeft {
      width: 5%;
      transition: all 0.8s ease;
      z-index: 100;
    }

    .buttonLeft:hover {
      -webkit-filter: brightness(50%);
      transition: all 0.8s ease;
    }

    .buttonRight {
      width: 5%;
      transform: rotate(180deg);      
      transition: all 0.8s ease;
      z-index: 100;
    }

    .buttonRight:hover {
      -webkit-filter: brightness(50%);
      transition: all 0.8s ease;
    }

    .carouselWrapper {
      display: flex;
      height: 100%;
      width: 70vw;
      overflow: hidden;
      // border: 10px solid green;


      .carouselItem {
        display: flex;
        flex: 0 0 50%;
        height: 100%;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        transition: transform 1s ease;

        .carouselImage {
          width: 100%;
          height: 90%;
          object-fit: contain;
        }

        .carouselCaption {
          width: 90%;
          height: 10%;
          align-self: center;
          text-align: center;
          color: #785444;
          font-size: 1vw;
          font-weight: 500;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }
    }
  }
}

.visitContainer {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  background-color: #ECDCD1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .visitText {
    z-index: 1;
    height: 20vh;
    width: 16vw;
    margin-right: 20vw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #145C1A;
    font-size: 2.5vw;
    font-weight: 500;
    display: inline-block;
  }

  .visitText span {
    display: inline-block;
    opacity: 0;
    /* Start with letters hidden */
    animation: fadeInUp 5s ease-in-out infinite;
  }

  .visitText span:nth-child(1) {
    animation-delay: 0.3s;
  }

  .visitText span:nth-child(2) {
    animation-delay: 0.6s;
  }

  .visitText span:nth-child(3) {
    animation-delay: 0.9s;
  }

  .visitText span:nth-child(4) {
    animation-delay: 1.2s;
  }

  .visitText span:nth-child(5) {
    animation-delay: 1.5s;
  }

  .visitText span:nth-child(6) {
    animation-delay: 1.8s;
  }

  .visitText span:nth-child(7) {
    animation-delay: 2.1s;
  }

  .visitText span:nth-child(8) {
    animation-delay: 2.4s;
  }

  .visitText span:nth-child(9) {
    animation-delay: 2.7s;
  }

  .visitText span:nth-child(10) {
    animation-delay: 3.0s;
  }

  .visitText span:nth-child(11) {
    animation-delay: 3.3s;
  }

  // /* Add a delay before fading out each letter */
  // .visitText span:nth-child(1):nth-child(4n+1) {
  //   animation-name: fadeOutDown;
  //   animation-delay: 1s; /* Wait for 1 second before fading out */
  // }

  // /* Add a delay before fading in each letter again */
  // .visitText span:nth-child(4n+4) {
  //   animation-name: fadeInUp;
  //   animation-delay: 2s; /* Wait for 2 seconds before fading in */
  // }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }

    25% {
      opacity: 1;
      transform: translateY(0);
    }

    75% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(10%);
    }
  }

  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      transform: translateY(0);
    }

    25% {
      opacity: 1;
      transform: translateY(0);
    }

    75% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(10%);
    }
  }
}