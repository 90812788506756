.container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: flex-start;
  scroll-padding-left: 15vw;
  scroll-padding-right: 15vw;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .container::-webkit-scrollbar {
    display: none;
  }

  .mobileItem {
    // border: 1px solid black;
    min-width: 60vw;
    max-width: 60vw;
    height: 100vh;
    scroll-snap-align: start;
    // margin-right: 1vw;
    // margin-left: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .desktopItem {
    // border: 1px solid black;
    min-width: 70vw;
    max-width: 70vw;
    height: 100vh;
    scroll-snap-align: start;
    // margin-right: 1vw;
    // margin-left: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }



  .mobileImage {
    max-height: 70vh;
    max-width: 50vw;
    aspect-ratio: 1 / 2.03;
    transition: transform 1s ease, translateX 1s ease;
    cursor: url('../../assets/cursor.png'), pointer;
  }

  .desktopImage {
    max-height: 60vh;
    max-width: 50vw;
    aspect-ratio: 2 / 1.16;
    transition: transform 1s ease, translateX 1s ease;
    cursor: url('../../assets/cursor.png'), pointer;
  }

  .firstImage {
    max-height: 40vh;
    max-width: 40vw;
    transition: transform 1s ease, translateX 1s ease;
  }


  .mobileVisibleText {
    position: absolute;
    bottom: 45vh;
    right: 2vw;
    font-size: 1vw;
    width: 25vw;
    padding: 5px;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
    opacity: 1;
    transform: translateY(-20px);
    display: block;
  }

  .mobileHiddenText {
    position: absolute;
    bottom: 45vh;
    right: 2vw;
    font-size: 1vw;
    width: 25vw;
    padding: 5px;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
    display: block;
    opacity: 0;
    transform: translateY(0px);
  }

  .desktopVisibleText {
    position: absolute;
    bottom: 45vh;
    right: 0vw;
    font-size: 1vw;
    width: 22vw;
    padding: 5px;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
    opacity: 1;
    transform: translateY(-20px);
    display: block;
  }

  .desktopHiddenText {
    position: absolute;
    bottom: 45vh;
    right: 0vw;
    font-size: 1vw;
    width: 22vw;
    padding: 5px;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
    display: block;
    opacity: 0;
    transform: translateY(0px);
  }
}