@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.wrapper {
  margin-top: -128px;
}

.content {
  height: 107vh;
  // position: relative;
  // z-index: -2;
}

.Parallax {
  position: relative;
  z-index: -3;
}

.textContent {
  position: absolute;
  top: 38%;
  left: 50%;
  padding: 5px 15px;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 50px;
  font-family: 'Quicksand', sans-serif;
}

.contentPeople {
  height: 107vh;
  background-color: black;
  max-height: 107vh;
  overflow: scroll;
  z-index: 1; /* Adjust z-index value to place it above other elements */
}

.swiper_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.swiper_slide {
  text-align: center;
  font-size: 18px;
  background: black;
}

.slideWrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  color: white;
  margin-top: 14%;
  margin-left: 5%;
}
.customPaginationBullet {
  height: 100%;
  width: 100%;
  background-color: black;
  margin-top: 25px!important;
  color: white;
}

.customPagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    // transform: translate3d(0, 0, 0);
    z-index: 10;
    width: 10%;
    margin-right: 85%;
    font-family: 'Quicksand', sans-serif;
    font-size: 30px;
}

.titleContainer {
  position: absolute;
  z-index: 1000000000;
  // height: 10%;
  // width: 20%;
  top: 33%;

  margin-left: 7.2vw;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
}

.img {
  width: calc(100%/3);
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 10px;
  align-items: center;
  transition: transform 0.3s;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-left: 3%;
}

.title {
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vw;
}

.text {
  font-family: 'Quicksand', sans-serif;
  font-size: 1vw;
  margin-top: 10%;
  width: 20vw;
}

.image {
  width: 2vw;
  margin-top: 1vh;
}