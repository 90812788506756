.container {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-align: center;
  overflow-x: hidden;

  .pinBox {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #396195;
    // border: 5px solid green;

    .pinSlider {
      bottom: -3vh;
      right: -3vw;
      position: absolute;
      display: flex;

      max-width: 100%;



      .pinSliderBackground {
        padding-bottom: 59.57910015%;
        width: 85vw;
        background-size: contain;
        background-position: center center;
        background-image: url('../../assets/macbook.png');
        background-repeat: no-repeat;
        // border: 5px solid red;


        .content {
          position: absolute;
          max-width: 100%;
          height: 100%;
          background-color: "#396195";
          z-index: -9999999;

          .caption {
            position: absolute;
            width: 20vw;
            right: 80vw;
            top: 0;
            // border: 5px solid red;
            display: flex;
            flex-direction: column
          }

          .caption h1 {
            color: white;
            font-size: 14vw;
            margin-top: 0vh;
            margin-bottom: 0vh;
            // border: 5px solid yellowgreen
          }

          .caption p {
            color: white;
            font-size: 2vw;
            font-weight: 600;
            margin-top: 0vh;
            // border: 5px solid yellowgreen
          }

        }

        .content img {
          margin-top: 7%;
          margin-right: 0.5%;
          width: 73%;
          object-fit: contain;
          z-index: 99;
        }
      }
    }
  }


  .intro {
    height: 100vh;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #396195;
  }

  @keyframes mymove {
    from {
      transform: translateY(20px);
    }

    to {
      transform: translateY(0px);
    }
  }


  .intro img {
    position: absolute;
    top: 90vh;
    max-width: 5vw;
    max-height: 10vh;
    aspect-ratio: 1 / 1;
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1s;
  }

  .intro h1 {
    color: white;
    font-size: 2vw;
    //   border: 1px solid black;
  }

  .conclusion {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: inline-block;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .marquee {
      position: absolute;
      white-space: nowrap;
      animation: marquee 20s linear infinite;
    }

    .marquee span {
      font-size: 5vw;
      font-weight: 600;
      color: #396195;
    }

    .marquee2 {
      animation-delay: 10s;
    }

    .interactiveContainer {
      padding-bottom: 50%;
      width: 80vw;
      z-index: 100;
      margin-left: 10vw;
      background-size: contain;
      background-position: center center;
      background-image: url('../../assets/macbook.png');
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;

      .interactiveScreen {
        position: absolute;
        margin-top: 6%;
        width: 61%;
        aspect-ratio: 3.2 / 2;
        z-index: 200;
        border: none;
      }
    }
    
  }

  @keyframes marquee {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

}