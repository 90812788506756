.swiper_container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  width: calc(100%);
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 10px;
  align-items: center;
  transition: transform 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.imgComputer {
  width: calc(100%/0.7);
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 10px;
  align-items: center;
  transition: transform 0.3s;
  margin-top: -110px;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.swiper_slide {
  width: 40%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

.sliderController {
  margin-top: -200px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background, adjust the opacity as needed */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  /* Initially, hide the overlay */
  transition: opacity 0.3s;
}

/* Show the overlay on hover */
.swiper_slide:hover .overlay {
  opacity: 1;
}